.coffee-block {
    /* background-color: #000000; */
    position: absolute;
    transition: all 0.2s linear;
    transform: translate(-50%,-50%);
    margin: 0;
    width: 0;
    height: 0;
    z-index: 8;
    user-select: none;
    transform-origin: center;
}

.coffee-block img {
    max-height: 110px;
    max-width: 124px;
    margin: 0;
    user-select: none;
    transform-origin: center;
    -webkit-animation: rotating 9s linear infinite;
    -moz-animation: rotating 9s linear infinite;
    -ms-animation: rotating 9s linear infinite;
    -o-animation: rotating 9s linear infinite;
    animation: rotating 9s linear infinite;
    /* filter: drop-shadow(1px 10px 15px rgba(29, 114, 242, 0.682)) */
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(-360deg);
      -moz-transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
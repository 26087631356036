.card-grid {
  display: grid;
  /* grid-template-rows: 1fr 1fr; */
  align-items: start;
  justify-items: center;
  justify-content: center;
  padding-bottom: 12px;
  /* margin-top: 12px; */
  /* min-height: 300px; */
  grid-gap: 8px;
}

.card-grid-advanced {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  justify-items: center;
  justify-content: center;
  padding-bottom: 12px;
  margin-top: 64px;
  grid-gap: 8px;
}

.game-grid {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  align-items: start;
  justify-items: center;
  justify-content: center;
  min-height: 300px;
  margin: 16px 0px 0px 2px;
  /* padding: 0 8px; */
}

.lower-game-grid {
  display: grid;
  grid-template-columns: 172px auto;
  /* padding: 0 8px; */
}

.empty-card-display {
  align-self: center;
  padding: 16px;
}

.bottom-section {
  height: 12vh;
  padding: 0 12px 12px 0;
  display: grid;
  justify-items: end;
  align-self: end;
}

.bottom-section-absolute {
  display: grid;
  justify-items: end;
  justify-self: center;
  /* position: absolute; */
  bottom: 15%;
  right: 39%;
  /* grid-gap: 4px; */
  margin-top: 6px;
  /* max-height: 100px; */
  justify-items: center;
}

.bottom-game-section {
  height: 200px;
  width: 100%;
  display: grid;
  justify-items: end;
  position: absolute;
  top: 557px;
  max-width: 760px;
  /* top: 724px; */
}

p {
  margin: 0;
}

.red-mark {
  background: none;
  font-weight: 900;
  color: rgba(236, 55, 55, 0.936);
  text-shadow: 1px 1px 3px black;
  font-size: 0.9rem;
  height: 2px;
  padding: 0 2px;
  text-decoration: underline;
}

.orange-mark {
  background: none;
  font-weight: 900;
  color: rgba(239, 129, 32, 0.936);
  text-shadow: 1px 1px 3px black;
  font-size: 0.8rem;
  height: 2px;
  padding: 0 2px;
  text-decoration: underline;
}

.red-mark-dark {
  background: none;
  font-weight: 900;
  color: rgba(192, 35, 35, 0.936);
  text-shadow: 1px 1px 2px black;
  font-size: 0.9rem;
  height: 2px;
  padding: 0 2px;
  /* text-decoration: underline; */
}

.green-mark {
  background: none;
  font-weight: 900;
  color: rgb(0, 228, 107);
  text-shadow: 1px 1px 2px black;
  font-size: 1.5rem;
  height: 2px;
  padding: 0 2px;
  text-decoration: underline;
}

.green-mark-dark {
  background: none;
  font-weight: 900;
  color: rgb(24, 141, 78);
  text-shadow: 1px 1px 2px black;
  font-size: 1.5rem;
  height: 2px;
  padding: 0 2px;
  text-decoration: underline;
}

.miss-mark {
  background: none;
  /* text-transform: uppercase; */
  font-weight: 900;
  color: inherit;
  text-shadow: 2px 2px 2px black;
  /* font-size: 1.5rem; */
  height: 2px;
  padding: 0 2px;
  /* text-decoration: underline; */
}

.vinny-mark {
  background: none;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Jumpman";
  color: lightblue;
  /* text-shadow: 1px 1px 2px black; */
  font-size: 0.9rem;
  height: 2px;
  padding: 0 2px;
}

.gram-mark {
  font-family: "Jumpman";
  color: #dfc523;
  text-transform: uppercase;
  background: none;
  font-weight: 500;
  /* text-shadow: 1px 1px 2px black; */
  font-size: 0.9rem;
  height: 2px;
  padding: 0 2px;
}

.main-vertical-grid {
  /* background-color: grey; */
  height: 98vh;
  max-width: 760px;
  width: 100vw;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;

  /* todo maybe shorten this */
  height: 556px;
  /* grid-template-rows: auto 40px; */
}

.vertical-grid-left {
  display: grid;
  align-self: center;
  justify-self: center;
  justify-items: center;
}

.vertical-grid-right {
  display: grid;
  align-self: center;
  justify-self: center;
  justify-items: center;
}

.main-tcg-body {
  background: #5e88b5;
}

.vertical-grid-left h1 {
  font-size: 2.4rem;
  color: rgb(19, 117, 215);
  font-family: "Jumpman";
  text-shadow: #dfc523 1px 1px 2px;
  margin-bottom: 24px;
  margin-top: 48px;
}

.center-grid {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  /* height: 50%; */
}

.card-img {
  border-radius: 12px;
  max-width: 55%;
  max-height: 620px;
  /* max-height: 100%; */
  /* max-height: 100%; */
  align-self: center;
  -webkit-tap-highlight-color: transparent;
}

.card-img-small {
  border-radius: 12px;
  max-width: 100%;
  max-height: 70%;
  /* max-height: 100%; */
  /* max-height: 100%; */
  align-self: center;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (orientation: landscape) and (max-height: 700px) {
  .help-img {
    height: 180px;
    width: 180px;
  }

  .card-img {
    max-height: 220px;
    max-width: 25%;
  }
}

.card-img {
  width: 400px;
}

.versus-text {
  color: white;
  font-size: 0.9rem;
  opacity: 0.84;
  text-shadow: 1px 1px 1px gold;
  text-align: center;
  justify-self: center;
  margin-top: 12px;
  z-index: 5;
  align-self: start;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.victory-text {
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
  font-family: "Jumpman";
  color: #3496ff;
  text-shadow: 3px 3px 2px rgb(0, 0, 0);
  /* margin-bottom: 16px; */
}

.victory-text-small {
  margin-top: 2px;
  font-size: 1.5rem;
  text-align: center;
  font-family: "Jumpman";
  color: #f09368;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
  /* margin-bottom: 16px; */
}

.button-option-grid {
  display: grid;
  grid-template-rows: auto 1px auto;
  grid-gap: 8px;
}

.card-button-grid {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 8px;
}

.qr-container {
  width: 100%;
  /* border-radius: 28px; */
  border: 2px solid yellow;
  display: grid;
}

.missing-card-text h2 {
  color: #f09368;
  font-size: 0.9rem;
  text-decoration: underline;
  margin: 0;
  margin-top: 16px;
}

.missing-card-text h2:hover {
  color: #ff5100;
  font-size: 0.9rem;
  text-decoration: underline;
}

.scanner-section {
  max-height: 300px;
}

.top-grid {
  display: grid;
  justify-items: center;
}

.player-hp-container {
    top: 10px;
    position: relative;
    border: rgb(249, 249, 249) 2px solid;
    background-color: rgba(255, 51, 0, 0.617);
    border-radius: 4px;
    text-align: end;
    height: 34px;
    display: grid;
    box-shadow: 2px 1px 1px black;
    transition: all 0.5 ease-in;
    user-select: none;
    margin-top: 12px;
    min-width: 150px;
  }
  
  .player-hp {
    margin: 0;
    display: flex;
    font-weight: 200;
    font-size: 28px;
    position: absolute;
    padding-left: 8px;
    align-self: center;
    font-kerning: 3px;
    font-stretch: expanded  ;
    text-shadow: 1px 2px 4px black;
    font-family: 'Jumpman';
    user-select: none;
  }
  

.release-text {
  font-size: 0.8em;
  opacity: 0.8;
  margin: 0;
}

.admin-link {
  color: white;
}

.loading-text {
  font-size: 2.8rem;
  font-family:'Jumpman', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #DFC523;
  position: absolute;
  top: 50%;
  -webkit-animation: fadeLoop 3s linear infinite;
  -moz-animation: fadeLoop 3s linear infinite;
  -ms-animation: fadeLoop 3s linear infinite;
  -o-animation: fadeLoop 3s linear infinite;
  animation: fadeLoop 3s linear infinite;
}

.bottomStoreGridContainer {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2px;
  align-items: start;
  align-content: start;
  justify-items: center;
  margin-top: 48px;
}

.cartButtonContainer {
  display: flex;
  align-items: start;
  justify-content: end;
  position: absolute;
  left: 14px;
  -webkit-tap-highlight-color: transparent;
}

.cartButtonContainer:hover {
  opacity: 0.5;
  transform: scale(110%);
  cursor: pointer;
}

.cartButtonContainer:active {
  opacity: 0.3;
  transform: scale(120%);
  cursor: pointer;
}

.followGramLink {
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: center;
  /* align-self: center; */
  /* padding-right: 6px; */
  padding-top: 10px;
}

.video-link {
  color: rgb(43, 146, 255);
  text-decoration: underline;
  cursor: pointer;
  font-size: 1em;
  font-weight: 400;
  /* margin-bottom: 2px; */
  margin-bottom: 0;
  font-family: 'Poynter';
}

.video-link:hover {
  color: rgb(109, 174, 243);
}

.news-close {
  color: black;
  opacity: 0.5;
  justify-self: end;
  cursor: pointer;
  padding: 6px;
}

.news-close:hover {
  opacity: 0.9;
}

.news-close:active {
  opacity: 0.9;
}

hr {
  border: none;
  border-top: 1px double #333;
  color: #333;
  overflow: visible;
  text-align: center;
  width: 100%;
  margin: 0;
}

/* .tiny-news {
  font-family: 'Poynter';
  font-size: 0.8em;
  margin-bottom: 20px;
  opacity: 0.8;
} */


a:link {
  text-decoration-color: #DFC523;
}

a:visited  {
  text-decoration-color: #82B4EA;
}

a:active  {
  text-decoration-color: #82B4EA;
}

a:hover {
  text-decoration-color: #82B4EA;
}

@-webkit-keyframes fadeLoop /* Safari and Chrome */ {
  0% {
    opacity: 1
  }
  70% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeLoop {
  0% {
    opacity: 1
  }
  70% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.product-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  /* max-width: 80%; */
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 2px;
  height: 99%;
  grid-gap: 2px;
  margin-top: 6px;
}

.product-grid::-webkit-scrollbar {
  display: none;
}

@media (min-width: 650px) {
  .product-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media (min-width: 720px) {
  .product-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1000px) {
  .product-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 8%;
  }
}


@media (min-width: 1400px) {
  .product-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 0 15%;
  }
}


.product-container {
  display: grid;
  grid-template-rows: auto auto auto auto;
  justify-items: center;
  margin-top: 8px;
  height: 204px;
}

.round-img {
  height: 120px;
  /* height: 90px; */
  max-width: 200px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.1s ease-in;
  object-fit: cover;
  /* filter: sepia(20%) hue-rotate(45deg);; */
  /* width: 180px; */
  border-radius: 50%;
  max-width: 130px;
}

.container-img {
  height: 120px;
  max-width: 150px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.1s ease-in;
    object-fit: cover;
  /* width: 180px; */
}

@media screen and (min-height: 350px) {
  .container-img  {
    max-width: 190px;
  }
}

@media screen and (min-height: 450px) {
  .container-img  {
    /* max-width: 170px; */
  }
}




.product-container img:hover {
  transform: scale(1.1);
}

.product-name {
  color: white;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  max-width: 150px;
}

.product-name:hover{
  color: rgb(225, 240, 13);
  font-weight: 900;
}

.product-price {
  color: white;
  /* margin: 12px 0 12px 0; */
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  cursor: pointer;
  margin: 0;
}

.storeHeader {
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1px;
  align-items: center;
  /* padding-top: 4px; */

}

.storeHeader h1 {
  color: white;
  size: 1.2rem;
  margin: 0;
  font-family: "Jumpman";
  text-align: center;
    -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.product-description {
  color: white;
  font-size: medium;
  margin-top: 12px;
  font-size: 0.9rem;
  max-width: 420px;
  margin-bottom: 8px;
  font-weight: 600;
}

.gramButton {
  align-self: center;
}

.product-details-container {
  display: grid;
  justify-items: center;
  margin-top: 12px;
  height: 60%;
}

.product-details-container img {
  /* height: 300px; */
  max-width: 75%;
  max-height: 340px;
  /* min-width: 250px; */
  align-self: end;
}

@media screen and (orientation:landscape) and (max-height: 700px) {
  .product-details-container img {
    max-height:110px;

  }}


.purchase-link {
  text-shadow: rgb(220, 229, 103) 1px 1px 2px;
  font-size: 1.25rem;
  margin: 0;
  color: rgb(43, 143, 243);
    font-weight: 400;
  font-family: "Jumpman";
  align-self: center;
  text-align: center;
  justify-self: center;
    -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
}

.purchase-link-highlighted {
  text-shadow: rgb(43, 143, 243) 1px 1px 2px;
  font-size: 1.2rem;
  color: rgb(239, 255, 12);
    font-weight: 400;
  font-family: "Jumpman";
  align-self: center;
  text-align: center;
  justify-self: center;
  margin: 8px;
    -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}



.purchase-link:hover {
  color: rgb(239, 255, 12);
  font-weight: 700;
}

.purchase-link:active {
  color: rgb(238, 255, 0);
  font-weight: 900;
}

.filter-link {
  text-shadow: rgb(220, 229, 103) 1px 1px 2px;
  font-size: 1.3rem;
  margin: 0;
  color: rgb(43, 143, 243);
    font-weight: 400;
  font-family: "Jumpman";
  align-self: center;
  text-align: center;
  justify-self: center;
    -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
}

.filter-link-highlighted {
  text-shadow: rgb(43, 143, 243) 1px 1px 2px;
  font-size: 1.3rem;
  color: rgb(239, 255, 12);
    font-weight: 400;
  font-family: "Jumpman";
  align-self: center;
  text-align: center;
  justify-self: center;
  margin: 0;
    -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}



.filter-link:hover {
  color: rgb(239, 255, 12);
  font-weight: 700;
}

.filter-link:active {
  color: rgb(238, 255, 0);
  font-weight: 900;
}

/* .purchase-link:visited {
  color: rgba(238, 255, 0, 0.699);
} */

.store-container {
  height: 100%;
}

.storeHeight {
  height: 68%;
}

.store-filter {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
}

.filter-toptions-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    justify-self: center;
    grid-gap: 12px;
    height: 24px;
}


.purchase-link-shimmer {
  text-shadow: rgb(220, 229, 103) 1px 1px 2px;
  font-size: 1.5em;
  color: rgb(43, 143, 243);
    font-weight: 400;
  font-family: "Jumpman";
  align-self: center;
  text-align: center;
  justify-self: center;
  margin: 8px;
    -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  background: linear-gradient;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  /* color: rgba(255, 255, 255, 0.1); */
  background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  -webkit-background-size: 125px 100%;
  -moz-background-size: 125px 100%;
  background-size: 125px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

@keyframes shimmer {
  from {
     transform: translateX(percentage);
  }
  to {
     transform: translateX(percentage);
  }
}

.main-store-container {
  padding: 0 1vw;
  height: 100vh;
}
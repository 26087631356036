
.log-container {
  /* margin-bottom: 64px; */
  overflow-y: scroll;
  width: 100%;
  justify-content: center;
  align-items: start;
  align-content: flex-start;
  display: grid;
  grid-gap: 0px;
  padding-top: 2px;
  justify-self: center;
  /* position: absolute; */
  /* margin-bottom: 100%; */
  align-self: end;
  height: 134px;
  background-color: #02182fd0;
  z-index: 2;

  -webkit-animation: slideInUp 0.2s;
  -moz-animation: slideInUp 0.2s;
  animation: slideInUp 0.2s;
}

.log-chaser {
  width: 20px;
  height: px;
}

.log-message {
  padding: 4px;
  max-width: 478px;
  font-weight: 700;
  text-align: center;
  color: white;
  font-size: 0.7em;
  justify-self: center;
  /* max-width: 80%; */
  /* margin: 6px 0; */
  opacity: 0;
  margin: 0;
  user-select: none;
}
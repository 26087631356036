.gramButton {
  padding: 0 4px;
  margin: 0 2px;
  background-color: #82B4EA;
  color: #DFC523;
  border-radius: 6px;
  border: #DFC523 1px solid;
  font-size: 1.2rem;
  font-family:'Jumpman', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-shadow: 3px 3px #241f1f;
  user-select: none;
  white-space:nowrap;
  -webkit-tap-highlight-color: transparent;
  min-height: 34px; 
  justify-self: center;
}

.gramButton:hover {
  filter: brightness(105%);
  cursor: pointer;
  transform: scale(105%);
}

.gramButton:active {
  filter: brightness(160%);
  transform: scale(100%);
  cursor: pointer;
}
.drop-gram-character {
    width: 100px;
    height: 100px;
    /* background-color: #000000; */
    position: absolute;
    transition: all 0.285s ease-out;
    align-self: end;
    user-select: none;
    z-index: 5;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.182))
}
.drop-gram-character-paper-img {
    position: absolute;
    top: 2px;
    right: 10px;
    user-select: none;
    z-index: 7;
}

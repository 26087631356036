.mute-button {
    position: absolute;
    top: 6px;
    right: 16px;
    color: white;
    opacity: 0.8;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.1s ease;
    user-select: none;
    background: none;
    -webkit-tap-highlight-color: transparent;
  }

  .mute-button:hover {
    opacity: 0.9;
    font-size: 1.7rem;
    background: none;
    -webkit-tap-highlight-color: transparent;
  }

  .mute-button:active {
    opacity: 1;
    filter: brightness(120%);
    font-size: 1.7rem;
    background: none;
    -webkit-tap-highlight-color: transparent;
  }


  .mute-button-videos-page {
    position: absolute;
    top: 60px;
    right: 21px;
    color: white;
    opacity: 0.8;
    font-size: 1.6rem;
    cursor: pointer;
    transition: all 0.1s ease;
    background: none;
    -webkit-tap-highlight-color: transparent;
  }

  .mute-button-videos-page:hover {
    opacity: 0.9;
    transform: scale(110%);
    background: none;
    -webkit-tap-highlight-color: transparent;
  }

  .mute-button-videos-page:active {
    opacity: 1;
    filter: brightness(120%);
    transform: scale(120%);
    background: none;
    -webkit-tap-highlight-color: transparent;
  }
.giant-text {
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
  font-family: "Jumpman", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  align-self: end;
  justify-self: center;
  font-weight: 600;
  text-shadow: 4px 4px 2px black;
  /* margin-bottom: 36px; */
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.story-bottom-display {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  justify-items: center;
  margin-top: 16px;
}

.youtube-link-container {
  background: #ff0000;
  color: white;
  border-radius: 18px;
  margin: 24px 0;
  margin-top: 32px;
  transition: all 0.1s ease;
  display: grid;
  justify-self: center;
}

.youtube-link-container:active {
  background: #7f1313;
  transform: scale(110%);

  filter: brightness(140%);

}

.youtube-link-container:focus {
  background: #871616;
  transform: scale(105%);
  filter: brightness(150%);

}

.youtube-link-container:hover {
  background: rgb(198, 27, 27);
  transform: scale(105%);
  filter: brightness(180%);
}

.youtube-link-container a {
  width: 100%;
  display: inline-block;
  font-size: 1.65rem;
  font-weight: 800;
  color: white;
  cursor: pointer;
  user-select: none;
}

.youtube-link-container a:hover {
  color: rgb(255, 255, 255);
}

.instagram-link-container {
  /* background: #df8c66;
  background: #a82d7b; */
  background: rgb(223, 140, 102);
  background: linear-gradient(
    90deg,
    rgba(223, 140, 102, 1) 16%,
    rgba(168, 45, 123, 1) 100%
  );
  color: white;
  margin: 24px 0;
  border-radius: 18px;
  border: white solid 6px;
  border-left: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}


.help-container {
  color: white;
  width: 88vw;
  text-align: center;
  max-width: 340px;
}

.help-container h1 {
  font-size: 1.7rem;
  color: rgb(19, 117, 215);
  font-family: "Jumpman";
  text-shadow: #dfc523 2px 2px 1px;
}

.help-container h2 {
  font-size: 1rem;
  color: rgb(207, 220, 25);
  font-family: "Jumpman";
}

.help-container p {
  font-size: 1rem;
  color: rgb(255, 255, 255);
  font-family: "Jumpman";
  align-self: center;
  text-align: center;
  justify-self: center;
  /* margin-top: 12px; */
}

.help-container img {
  /* max-width: 400px; */
  justify-self: center;
  /* color: #7ca5cedb; */
}

.help-img {
  width: 300px;
  cursor: pointer;
  margin-bottom: 4px;
}

.help-img:hover {
  transform: scale(103%);
}

.help-img:active {
  transform: scale(106%);
}
.instagram-link-container:active {
  filter: brightness(140%);
  transform: scale(105%);
  cursor: pointer;
}

.instagram-link-container:focus {
  filter: brightness(130%);
  transform: scale(110%);
}

.instagram-link-container:hover {
  filter: brightness(140%);
  cursor: pointer;
  transform: scale(105%);
}

.instagram-link-container a {
  width: 100%;
  padding: 0px 0;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 800;
  color: white;
  cursor: pointer;
  user-select: none;
}

.drop-gram-game-container {
  height: 100vh;
  width: 100vw;
  /* padding: 20px 80px; */
  /* margin-top: 48px; */
  height: 416px;
  width: 330px;
  display: grid;
  user-select: none;
  position: relative;
  justify-self: center;
  /* background-color: rgb(85, 163, 247); */
  overflow: hidden;
  grid-template-rows: 1fr 20px;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;    
  -webkit-tap-highlight-color: transparent;
  /* filter: brightness(90%); */

}

.drop-gram-controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* height: 80px; */
  justify-self: center;
  grid-gap: 4px;
}

.drop-gram-controls button {
  opacity: 0.8;
  font-size: 2rem;
  height: 24px;
  width: 160px;
  margin: 0;
  padding: 24px;
  display: grid;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.floor-bg-stationary {
    /* animation: slideShort 8.5s linear infinite; */
    user-select: none;
    /* width: 2560px; */
}

.stuff-bg-stationary {
  position: absolute;
  height: 140px;
  top: -30px;
  /* width: 12767px; */
  user-select: none;
  width: 768px;
  filter: blur(2px) saturate(100%) opacity(80%) contrast(60%);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  background-size: auto 95%;
}

.vinny-throw-container {
  position: absolute;
  top: 55px;
  right: 10px;
  user-select: none;
  z-index: 2;
}

.vinny-throw-container img {
  height: 114px;
}

.drop-gram-background {
  background-size: contain;
}
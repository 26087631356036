
.rising-text {
  opacity: 0;
  color: red;
  font-weight: 900;
  text-align: center;
  z-index: 5;
  position: absolute;
  top: 90px;
  -webkit-animation: slideUp 5s ease;
  -moz-animation: slideUp 5s ease;
  animation: slideUp 5s ease;
  user-select: none;
}

.rising-text h1 {
  font-size: 2.3rem;
  text-shadow: 2px 2px 4px black;
  z-index: 5;
  user-select: none;
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-40px);
    opacity: 0;
  }
}
@-moz-keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}
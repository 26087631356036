
.loading-container {
    display: grid;
    height: 100%;
    position: absolute;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    left: 0%;
    width: 100%;
    top: 0%;
    z-index: 100;
    background-color:  rgb(43, 146, 255);
    opacity: 1;
    /* pointer-events: none; */
}

.banana-spinner-container {
    display: grid;
    height: 50px;
    /* grid-template-columns: 1fr;
    grid-template-rows: 1fr; */
    justify-self: center;
    align-items: center;
    justify-items: center;
    /* left: 0%; */
    /* top: 0%; */
    /* z-index: 100; */
    /* background-color:  rgb(43, 146, 255); */
    opacity: 1;
}

.banana-spinner {
  height: 40px;
  width: 30px;
  opacity: 0.8;
  margin-bottom: 100px;
  -webkit-animation: rotating 6s linear infinite;
  -moz-animation: rotating 6s linear infinite;
  -ms-animation: rotating 6s linear infinite;
  -o-animation: rotating 6s linear infinite;
  animation: rotating 6s linear infinite;
}

.loading-container img {
    height: 100px;
    width: 80px;
    opacity: 0.8;
    margin-bottom: 120px;
    -webkit-animation: rotating 6s linear infinite;
    -moz-animation: rotating 6s linear infinite;
    -ms-animation: rotating 6s linear infinite;
    -o-animation: rotating 6s linear infinite;
    animation: rotating 6s linear infinite;
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    to {
      -webkit-transform: rotate(-270deg);
      -o-transform: rotate(-270deg);
      transform: rotate(-270deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    to {
      -ms-transform: rotate(-270deg);
      -moz-transform: rotate(-270deg);
      -webkit-transform: rotate(-270deg);
      -o-transform: rotate(-270deg);
      transform: rotate(-270deg);
    }
}
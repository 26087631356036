a {
    text-decoration: none;
  }
  
  .main-jump-header {
    font-family:'Jumpman', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: #DFC523;
    font-weight: 700;
    font-size: 2rem;
    word-wrap: none;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
  }
  
  .game-container {
    height: 100vh;
    width: 100vw;
    /* padding: 20px 80px; */
    /* margin-top: 48px; */
    height: 316px;
    width: 430px;
    display: grid;
    user-select: none;
    position: relative;
    justify-self: center;
    /* background-color: rgb(85, 163, 247); */
    overflow: hidden;
    grid-template-rows: 1fr 20px;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;    
    -webkit-tap-highlight-color: transparent;
    /* filter: brightness(90%); */

  }

  .paper-icon-container {
    position: absolute;
    top: 0;
    right: 0;
    user-select: none;
    /* background-color: rgba(0, 0, 0, 0.143);
    height: 75px;
    width: 75px; */
  }



  .paper-icon-container img {
    position: absolute;
    right: 8px;
    top: 4px;
    height: 60px;
    user-select: none;
  }

  .second-paper {
    z-index: 3;
    margin-right: 90px;
  }


  .sliding-background {
    animation: slideLong 100s linear infinite;
    height: 100%;
    width: 25534px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    /* height: 220px;
    width: 200px; */
  }

  .sliding-carpet-background {
    animation: slideShort 8.5s linear infinite;
    user-select: none;
    width: 2560px;

    /* height: 20px; */
    /* height: 220px;
    width: 200px; */
  }

  .sliding-stuff-background {
    position: absolute;
    animation: slideMedium 10s linear infinite;
    height: 330px;
    top: 48px;
    /* width: 12767px; */
    user-select: none;
    width: 768px;
    filter: blur(2px) saturate(100%) opacity(80%) contrast(60%);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    background-size: auto 75%;
    /* height: 20px; */
    /* height: 220px;
    width: 200px; */
  }

  .jump-score {
    position: absolute;
    left: 16px;
    margin: 0;  
    color: #DFC523;

    /* color: #2b8ff4; */
    font-size: 3.8rem;
    user-select: none;
    font-family: "Jumpman";
    text-shadow: -2px 3px 2px #002040;
    z-index: 2;
    top: -10px;
  }



  .news-score {
    position: absolute;
    right: 18px;
    margin: 0;  
    color: #2b8ff4;

    /* color: #DFC523; */
    font-size: 1.5rem;
    text-align: center;
    user-select: none;
    font-family: "Jumpman";
    text-shadow: -2px 3px 2px #002040;
    z-index: 2;
  }

  .top-jump-bar {
    position: absolute;
    /* background-color: rgba(0, 0, 0, 0.15); */
    width: 100%;
    height: 60px;
    background: linear-gradient(
      rgba(0, 0, 0, 0.502), 
      rgba(0, 0, 0, 0.0)
    )
  }
/* 
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1692px, 0, 0); /* The image width 
  }*/

  .jump-div {
     height: 50vh;
     width: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    /* z-index: 2; */
  }


  @keyframes slideShort {
    0% {
      background-position : 0px 0px
    }
    100% {
      background-position : -1280px 0px
    }
  }

  @keyframes slideMedium {
    0% {
      background-position : 0px 0px
    }
    100% {
      background-position : -1280px 0px
    }
  }

  @keyframes slideLong {
    0% {
      background-position : 0px 0px
    }
    100% {
      background-position : -12767px 0px
    }
  }


  .highscore-row {
    display: grid;
    justify-content: start;
    grid-template-columns: 80% 20px;
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 0.15em;
    /* color: #f09368; */
    text-shadow: 2px 2px 2px #000810;
    text-align: start;
    font-family: "Jumpman";
    color: #3496ff;
  }

  .you-text {
    color: #DFC523;
  }

  .highscore-value {
    color: #f09368;
    text-shadow: 2px 2px 2px #100e00;
    text-align: end;
    justify-self: end;
    width: 20px;
    /* color: #dbe72f; */
  }

  .highscores-grid {
    grid-gap: 4px;
    display: grid;
    margin-left: 8px;
    /* margin-bottom: 32px; */
    margin-right: 8px;
  }

  .gram-input {
    /* font-family: "Jumpman"; */
    font-weight: 900;
    background: none;
    color: #DFC523;
    max-width: 120px;
    font-size: 1.5rem;
    text-shadow: 2px 2px 2px #000810;

  }
.block {
    /* background-color: #000000; */
    position: absolute;
    transition: all 0.8s linear;
    transform:translate(-50%,-50%);
    margin: 0;
    width: 0;
    height: 0;
    user-select: none;
    transform-origin: center;
}

.block img {
    max-height: 100px;
    max-width: 108px;
    margin: 0;
    user-select: none;
    transform-origin: center;
    /* filter: drop-shadow(1px 10px 15px rgba(29, 114, 242, 0.682)) */
}
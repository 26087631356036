.main-container {
  padding: 0 1vw;
  height: 100vh;
  /* overflow-x: hidden; */
  /* z-index: -1; */
  transition: opacity 0.1s ease-out;
}


.ytp-big-mode.ytp-embed:not(.ad-showing) .ytp-title-text {
  padding-top: 18px;
}
.ytp-big-mode .ytp-title-channel-logo {
  visibility: hidden;
}
.ytp-big-mode.ytp-title-enable-channel-logo .ytp-title-text {
  padding-left: 6px;
}

.ytp-cued-thumbnail-overlay .ytp-cued-thumbnail-overlay-image {
  background-position: bottom !important;
}

.ytp-title-channel-logo {
  visibility: hidden;
  display: none;
}


@media (min-width: 500px) {
  .main-container {
    padding: 0;
  }
}


@media (min-width: 740px) {
  .main-container {
    padding: 0vw;
  }
}


@media (min-width: 1100px) {
  .main-container {
    height: 100%;
    padding: 0 8vw;
  }
}

@media screen and (orientation:landscape)
  and (max-device-width: 680px) {
  .main-container {
    overflow: scroll;
  }
}

.mainContentHeight {
  height: 79vh;
  /* position: relative; */
}

.videos-scrollbox {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 10vh;
  max-height: 252px;
  justify-self: end;
  width: 154px;
  margin-left: 12px;
  align-self: start;
}


.games-scrollbox {
  margin-top: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-self: start;
  width: 178px;
  margin-left: 6px;
  height: 90px;
  align-self: start;
  margin-bottom: 12px;
}

.left-side-grid {
  display: grid;
  justify-items: center;
}

.release-text {
  font-size: 0.8em;
  opacity: 0.8;
  margin: 0;
}
/* 
@media screen and (orientation:portrait) and (min-height: 700px) {
  .mainContentHeight {
    height:82vh;
  }
} */

@media screen and (orientation:landscape) {
  .mainContentHeight {
    /* height:87vh; */
    height: 84vh;
  }

  /* .mainBody {
    height: 114vh;
  } */
}



@media screen and (orientation:landscape) and (min-height: 400px) {
  .mainContentHeight {
    height:84vh;
  }
}

  .mainBody {
    height: 100vh;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    .mainBody {
      height: 114vh;
    }
  }

/* @media screen and (orientation:landscape) and (min-height: 380px) {
  .mainContentHeight {
    height: 60vh;
  }
} */

.admin-link {
  color: white;
}

.loading-text {
  font-size: 2.8rem;
  font-family:'Jumpman', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #DFC523;
  position: absolute;
  top: 50%;
  -webkit-animation: fadeLoop 3s linear infinite;
  -moz-animation: fadeLoop 3s linear infinite;
  -ms-animation: fadeLoop 3s linear infinite;
  -o-animation: fadeLoop 3s linear infinite;
  animation: fadeLoop 3s linear infinite;
}

.mainBody {
  width: 100vw;
  background-color: black;
  background-size: cover;
}

.bottomGridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* align-items: start; */
  align-content: start;


  -webkit-animation: slideInDown 0.4s;
  -moz-animation: slideInDown 0.4s;
  animation: slideInDown 0.4s;
  
}

.buttonContainer {
  display: flex;
  align-items: start;
  justify-content: end;
  margin-top: 4px;
  -webkit-tap-highlight-color: transparent;
}


.video-link {
  color: rgb(43, 146, 255);
  text-decoration: underline;
  cursor: pointer;
  font-size: 1em;
  font-weight: 400;
  margin-top: 2px;
  /* margin-bottom: 2px; */
  margin-bottom: 0;
  font-family: 'Poynter';
  overflow: hidden;
  white-space: nowrap;
}

.video-link:hover {
  color: rgb(109, 174, 243);
}

.news-header {
  width: 300px;
  font-family: 'Poynter';
  min-width: 200px;
  font-size: 1.4rem;
  margin: 0 6px;
  opacity: 0.8;
  word-wrap: none;
  text-align: center;
  text-transform: uppercase;
}

.news-sub-header {
  font-family: 'Poynter';
  min-width: 200px;
  font-size: 0.8rem;
  font-style: italic;
  opacity: 0.9;
  word-wrap: none;
  text-align: center;
  text-transform: uppercase;
}

.little-news-header {
  font-family: 'Poynter';
  /* min-width: 200px; */
  font-size: 1em;
  margin: 0;
  opacity: 0.8;
  word-wrap: none;
}


.news-close {
  color: black;
  opacity: 0.5;
  justify-self: end;
  cursor: pointer;
  padding: 6px;
}

.news-close:hover {
  opacity: 0.9;
}

.news-close:active {
  opacity: 0.9;
}

hr {
  border: none;
  border-top: 1px double #333;
  color: #333;
  overflow: visible;
  text-align: center;
  width: 100%;
}

/* .tiny-news {
  font-family: 'Poynter';
  font-size: 0.8em;
  margin-bottom: 20px;
  opacity: 0.8;
} */

.news-row {
  display: grid;
  grid-template-columns: 1fr 18px;
  align-items: center;
}

.news-picture-row {
  display: grid;
  grid-template-columns: 30% auto;
  align-items: center;
}

.gram-img {
  height: 106px;
  align-self: start;
  filter: grayscale(90%);
  /* margin-top: 16px; */
}

.cursive-gram {
  font-family: cursive;
  margin: 0;
  text-align: center;
  justify-self: center;
  font-size: 0.8rem;
}

a:link {
  text-decoration-color: #DFC523;
}

a:visited  {
  text-decoration-color: #82B4EA;
}

a:active  {
  text-decoration-color: #82B4EA;
}

a:hover {
  text-decoration-color: #82B4EA;
}

@-webkit-keyframes fadeLoop /* Safari and Chrome */ {
  0% {
    opacity: 1
  }
  70% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeLoop {
  0% {
    opacity: 1
  }
  70% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.arrow-icon {
  font-size: 1.1rem;
  align-self: center;
  padding-left: 6px;
}

.play-button-img {
  font-size: 3rem;
  /* width: 90px; */
  height: 120px;

  -webkit-animation: fadeIn 0.1s ease;
  -moz-animation: fadeIn 0.1s ease;
  animation: fadeIn 0.1s ease;
}


@-webkit-keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.player-container {
  /* height: 330px; */
  /* padding: 0px 6px 8px 6px; */
  /* background-color: rgb(50, 143, 169); */
  border-radius: 6px;
  -webkit-tap-highlight-color: transparent;
  /* border: 2px yellow solid; */
  /* min-width: 180px; */
}

.player-name {
  text-transform: uppercase;
  font-size: 1.7em;
  font-weight: 900;
  /* text-align: start; */
  margin-top: 48px;
  font-family: "Jumpman";
  color: white;
  text-shadow: 2px 2px 2px black;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.tally-text {
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 900;
  /* text-align: start; */
  margin-top: 48px;
  font-family: "Jumpman";
  color: white;
  text-shadow: 2px 2px 2px black;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}


.player-img {
  /* width: 85%; */
  width: 134px;
  padding: 20px;
  justify-self: center;
  height: 336px;
  max-height: 350px;
  margin-top: 30px;
  -webkit-tap-highlight-color: transparent;
  transform-origin: top;
  user-select: none;
  transform-origin: center center;
  /* height: 485px; */
  position: absolute;
  -webkit-filter: drop-shadow(2px 1px 0 white) drop-shadow(-2px -1px 0 white);
  filter: drop-shadow(2px 1px 0 white) drop-shadow(-2px -1px 0 white);
}

.player-ability {
  margin: 0;
  text-transform: uppercase;
  border-bottom: 3px solid black;
  font-size: 1em;
}

.player-description {
  font-size: 0.8em;
  margin: 6px 0;
  height: 30px;
  max-width: 200px;
}

.player-attack {
  /* padding: 12px 0; */
  text-overflow: none;
  word-wrap: all;
  border: 2px solid black;
  text-align: center;
  font-size: 1em;
}

.horizontal-grid {
  display: grid;
  margin: 0 12px 48px 12px;
  align-content: center;
  height: 20px;
  grid-template-rows: 58px 25px;
}

.player-vertical-grid {
  display: grid;
  /* width: 200px; */
  /* grid-template-rows: 48px 190px 28px 40px 28px; */
  /* padding: 8px; */
}

.crit-text {
  /* position: absolute; */
  text-align: center;
  user-select: none;
  color: red;
  font-size: 0.8em;
  margin: 0;
  text-decoration: dashed;
  /* text-align: center; */
  font-weight: 600;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.336);
  padding: 8px 0;
  /* padding-left: 12px; */
}

.crit-mark {
  user-select: none;
  font-size: 1.6em;
  font-weight: 900;
  color: red;
  background: none;
}

.button-grid {
  user-select: none;
  display: grid;
  justify-content: center;
  padding-bottom: 12px;
  grid-gap: 6px;
  background-color: #02182fd0;
  border-radius: 24px 24px 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-animation: slideInUp 0.2s;
  -moz-animation: slideInUp 0.2s;
  animation: slideInUp 0.2s;
}

.slide-in-right {
  animation: slideInRight 0.8s ease;
}

.slide-in-left {
  animation: slideInLeft 0.8s ease;
}


@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(70px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slideInUp {
  0% {
    transform: translateY(70px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    transform: translateY(70px);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(300px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes slideInRight {
  0% {
    transform: translateX(280px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(280px);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-280px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes slideInLeft {
  0% {
    transform: translateX(-280px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-280px);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes shaking {
  0% {
    -webkit-transform: translateX(-4px);
  }
  50% {
    -webkit-transform: translateX(4px);
  }
  100% {
    -webkit-transform: translateX(-4px);
  }
}
@-moz-keyframes shaking {
  0% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(-4px);
  }
}
@keyframes shaking {
  0% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(-4px);
  }
}
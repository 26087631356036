.tic-tac-gram-game-container {
  height: 100vh;
  width: 100vw;
  /* padding: 20px 80px; */
  /* margin-top: 48px; */
  height: 416px;
  width: 330px;
  display: grid;
  user-select: none;
  position: relative;
  justify-self: center;
  /* background-color: rgb(85, 163, 247); */
  overflow: hidden;
  grid-template-rows: 1fr 20px;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;    
  -webkit-tap-highlight-color: transparent;
  /* filter: brightness(90%); */

}

.tic-tac-gram-controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 80px;
  justify-self: center;
}

.tic-tac-gram-controls button {
  opacity: 0.8;
  font-size: 4rem;
  height: 120px;
  width: 160px;
  margin: 6px;
  padding: 24px;
}

.tic-tac-gram-background {
  background-size: contain;
}

.tic-tac-grid {
  display: grid;
  position: absolute;
  justify-self: center;
  align-self: center;
  height: 80%;
  width: 90%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.game-cell {
  border: 1px solid rgba(131, 131, 131, 0.381);
  display: grid;
  z-index: 3;
}

.game-cell:hover {
  /* border: 2px solid black; */
  z-index: 3;
  background-color: #82B4EA;
}

.game-cell:active {
  /* border: 2px solid black; */
  z-index: 3;
  background-color: #82B4EA;
}

.game-cell-lit {
  border: 1px solid rgba(131, 131, 131, 0.559);
  display: grid;
  z-index: 3;
  background-color: #82B4EA;
}

.cell-img {
  height: 90px;
  /* width: 90px; */
  justify-self: center;
  align-self: center;
  /* transform: translate(50%,50%); */
}

.tic-tac-score {
  position: absolute;
  left: 16px;
  margin: 0;  
  color: #DFC523;

  /* color: #2b8ff4; */
  font-size: 3.8rem;
  user-select: none;
  font-family: "Jumpman";
  text-shadow: -2px 3px 2px #002040;
  z-index: 10;
  top: -10px;
}

.tic-tac-refresh-container {
  position: absolute;
  right: 12px;
}

.tic-tac-refresh-container button {
  padding: 2px;
  margin-top: 2px;
  /* display: absolute; */
}

.victory-img {
  height: 100px;
  justify-self: center;
}
.throwdisplay {
    position: absolute;
    transition: all 0.8s linear;
    padding: 8px;
    user-select: none;
}

.throwdisplay img {
    transform:translate(-50%,-50%);
    transform-origin: center;
    user-select: none;
}
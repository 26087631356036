.coffee-gram-game-container {
  height: 100vh;
  width: 100vw;
  /* padding: 20px 80px; */
  /* margin-top: 48px; */
  height: 330px;
  width: 330px;
  display: grid;
  user-select: none;
  position: relative;
  justify-self: center;
  /* background-color: rgb(85, 163, 247); */
  overflow: hidden;
  grid-template-rows: 1fr 20px;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;    
  -webkit-tap-highlight-color: transparent;
  /* filter: brightness(90%); */

}

.coffee-gram-controls {
  display: grid;
  grid-template-columns: 1fr;
  justify-self: center;
  justify-content: center;
  justify-items: center;
}

.coffee-gram-castbar {
  height: 40px;
  margin-top: 40px;
}


.coffee-gram-controls-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 40px;
  justify-self: center;
}

.coffee-gram-controls button {
  opacity: 0.8;
  font-size: 2rem;
  height: 60px;
  width: 120px;
  margin: 6px;
  padding: 8px;
}

.floor-bg-stationary {
    /* animation: slideShort 8.5s linear infinite; */
    user-select: none;
    /* width: 2560px; */
}

.stuff-bg-stationary {
  position: absolute;
  height: 140px;
  top: -30px;
  /* width: 12767px; */
  user-select: none;
  width: 768px;
  filter: blur(2px) saturate(100%) opacity(80%) contrast(60%);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  background-size: auto 95%;
}

.vinny-throw-container {
  position: absolute;
  top: 55px;
  right: 10px;
  user-select: none;
  z-index: 2;
}

.vinny-throw-container img {
  height: 114px;
}

.coffee-gram-background {
  background-size: contain;
}

.newspaper {
  /* width: 160px;
  height: 80px; */
  /* position: absolute; */
  /* left: 79vw; */
  /* top: 55%; */
  border-radius: 24px;
  float: right;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0.95;
    -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform-origin: center center;
}

.newspaper img {
  height: 36px;
  display: block;
  transform: rotate(-3deg);
  /* position: absolute; */
  /* pointer-events: none; */
  transform: translateY(50%);
  transform: translateX(50%);
  box-shadow: 6px 6px 8px black;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.newspaper img:hover {
  filter: brightness(130%);
  height: 40px;
  opacity: 0.9;
}

.newspaper img:active {
  filter: brightness(150%);
  height: 40px;
  opacity: 1;
}

/* Widescreen rules only */
@media screen and (min-width: 600px) {
  .newspaper {
    /* top: 60%; */
    left: 82vw;
  }
}

/* Widescreen rules only */
@media screen and (min-width: 1000px) {
  .newspaper {
    /* top: 60%; */
    left: 78vw;
  }
}


/* Widescreen rules only */
@media screen and (min-width: 1600px) {
  .newspaper {
    /* top: 60%; */
    left: 82vw;
  }
}

.newspaper-container {
  position: absolute;
  width: 100%;
  top: 100px;
  right: 36px;
  z-index: 3;
}

/* Widescreen rules only */
/* @media screen and (orientation:landscape) {
  .newspaper-container {
    top: 3%;
  }
} */
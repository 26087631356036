
/* h1 {
  font-size: 1.8rem;
  margin: 0;
  align-self: center;
} */

.modal-container {
  z-index: 100;
  /* box-shadow: rgba(0, 0, 0, 0.452) 6px 7px 9px; */
  /* border-radius: 3px; */
  /* width: 33%; */
  min-width: 230px;
  /* min-height: 200px; */
  /* height: 22%; */
  /* border: 1px rgb(130, 130, 130) solid; */
  /* background-color: #cdcdcd; */
  background-size: cover;
  position: absolute;
  /* top: 280px; */
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px 12px;
  display: grid;
  border: none;
  transition: opacity 0.1s ease-in;
  max-width: 550px;
  min-width: 256px;
  -webkit-filter: drop-shadow(6px 4px 4px white) drop-shadow(-5px -4px 4px white);
  filter: drop-shadow(6px 4px 4px white) drop-shadow(-5px -4px 4px white);
}


@media screen and (orientation:landscape) {
  .modal-container {
    top: 200px;
  }
}

/* 
.modal-container img {
  transform: rotate(180deg);
} */

.modal-margin {
  justify-self: center;
  display: grid;
  grid-gap: 1px;
  /* padding: 0 2px 2px 1px; */
}
.coffee-gram-character {
    width: 100px;
    height: 100px;
    /* background-color: #000000; */
    position: absolute;
    transition: all 0.285s ease-out;
    align-self: end;
    user-select: none;
    z-index: 5;
    padding: 24px;
    transform-origin: center center;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.182));
    cursor: pointer;

}
.coffee-gram-character-img {
    /* filter:opacity(0.9) brightness(100%); */
    /* position: absolute;
    top: 2px;
    right: 10px; */
    transform-origin: center center;
    transform: scaleX(-1) scale(110%);
    user-select: none;
    z-index: 7;
}

.coffee-gram-character-selected {
    filter:opacity(0.9) brightness(120%) contrast(150%);
    border-bottom: 8px #82B4EA solid;
    border-top: 8px #82B4EA solid;
    border-radius: 8px;
    transform: scale(115%) scaleX(-1);
    transform-origin: center center;
    /* transform: scaleX(-1); */
    user-select: none;
    z-index: 7;
}

.more-funny-videos-link {
    /* margin-top: 32px; */
    margin: 8px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 32px;
}


.more-funny-videos-link a {
    color: #DFC523;
    padding: '0 12px';
    text-decoration: underline;
    font-size: 1.7rem;
    font-family: "Jumpman";
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.86);
}
  
.more-funny-videos-link a:hover {
    color: #f2d51a;
    font-weight: 700;
}

.more-funny-videos-link a:active {
    color: #ffdf0e;
    font-weight: 900;
}

.more-funny-videos-link p {
    margin: 0;
    text-shadow: black 1px 1px 1px;
    font-size: 1.25rem;
    color: rgb(19, 117, 215);
    font-family: "Jumpman";
    align-self: center;
    text-align: center;
    justify-self: center;
    margin-top: 4px;
}
.game-corner-link a{
    color: #82B4EA;
}

.game-corner-link a:hover{
    color: #409afa;
}

.game-corner-link a:active{
    color: #1385ff;
}

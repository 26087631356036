

.bottomGridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* align-items: start; */
  align-content: start;


  -webkit-animation: slideInDown 0.4s;
  -moz-animation: slideInDown 0.4s;
  animation: slideInDown 0.4s;
  z-index: 3px;
  
}

.buttonContainer {
  display: flex;
  align-items: start;
  justify-content: end;
  margin-top: 4px;
  -webkit-tap-highlight-color: transparent;
}

.iconsContainer {
  display: flex;
  align-items: start;
  justify-content: center;
}

.iconsContainer img {
  padding: 0 4px;
  opacity: 0.85;
  transition: all 0.2 ease;
}

.iconsContainer img:hover {
  filter: brightness(140%);
  transform: scale(120%);
}

.iconsContainer img:active {
  filter: brightness(160%);
  transform: scale(120%);
}


.followGramLink {
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: center;
}

.followGramText {
  font-family:'Jumpman', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: rgba(255, 255, 255, 0.732);
  font-size: 1rem;
  white-space: nowrap;
  user-select: none;
}

.followGramText:hover {
  color: rgba(255, 255, 255, 0.911);
  filter: brightness(140%);
  transform: scale(110%);
}

.followGramText:active {
  color: rgba(255, 255, 255, 0.911);
  filter: brightness(160%);
  transform: scale(110%);
}

.arrow-icon {
  font-size: 1.1rem;
  align-self: center;
  padding-left: 6px;
}

.center-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  /* padding-right: 12px; */
  height: 26px;
  padding-bottom: 52px;
}

.bottom-nav-volume-button-grid-videos {
  justify-self: end;
  align-self: center;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
}

.volume-icon svg {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.7));
}

.bottom-nav-volume-button-grid-videos svg {
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.7));
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slideInDown {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
.likes-text {
  font-size: 30px;
  font-weight: 900;
  font-family:'Jumpman', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: white;
  text-shadow: rgb(43, 146, 255) 1px;
  position: absolute;
  top: 80%;
  text-shadow: rgb(0, 0, 0) 4px 4px 4px;
}

.likes-label{
  font-size: 14px;
  font-family:'Jumpman', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #DFC523;
  text-shadow: rgb(0, 0, 0) 4px 1px 4px;
  position: absolute;
  font-weight: 200;
  top: 75%;
  width: 100px;
  right: 54%;
  z-index: 120;
}

.gram-hand {
  height: 220px;
  transform: rotate(-90deg);
  position: absolute;
  top: -72px;
  right: 75px;
  z-index: 1010;
  /* -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

.gram-thumbsup {
  height: 80px;
  top: -12px;
  display: block;
  right: -44px;
  transform: rotate(-3deg);
  position: absolute;
  pointer-events: none;
  z-index: 1010;
  /* -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}



.high-five-small {
  width: 200px;
  height: 90px;
  position: absolute;
  left: 89vw;
  /* top: 55%; */
  float: right;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-animation: slideOut 0.8s;
  -moz-animation: slideOut 0.8s;
  animation: slideOut 0.8s;
  filter: brightness(125%);
  z-index: 1010;
}

.high-five-big {
  width: 200px;
  height: 90px;
  position: absolute;
  left: 75vw;
  /* top: 55%; */
  border-radius: 24px;
  float: right;
  cursor: pointer;
  -webkit-animation: slideIn 0.1s;
  -moz-animation: slideIn 0.1s;
  animation: slideIn 0.1s;
  filter: brightness(125%);
  z-index: 1010;

}

.speech-bubble-container {
  right: 122px;
  /* top: -152%; */
  bottom: 120px;
  position: absolute;
}

.speech-bubble {
  background-color: white;
  border-radius: 28px;
  border: black solid 2px;
  padding: 12px;
  opacity: 80%;
  text-align: center;
  -webkit-animation: fadeInSlideUp 0.4s;
  -moz-animation: fadeInSlideUp 0.4s;
  animation: fadeInSlideUp 0.4s;
  font-size: 1rem;
  font-weight: 700;
  min-height: 10px;
  min-width: 74px;
}

.speech-bubble-hidden {
  -webkit-animation: fadeOut 0.2s;
  -moz-animation: fadeOut 0.2s;
  animation: fadeOut 0.2s;
  background-color: white;
  border-radius: 28px;
  border: black solid 2px;
  padding: 12px;
  opacity: 90%;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  min-height: 10px;
  min-width: 74px;
  opacity: 0;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: white;
  border-bottom: 0;
  margin-left: -17px;
  margin-bottom: -15px;

}

.speech-bubble-hidden:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: white;
  border-bottom: 0;
  margin-left: 11px;
  margin-bottom: -15  px;
  opacity: 0;
  -webkit-animation: fadeOut 0.3s;
  -moz-animation: fadeOut 0.3s;
  animation: fadeOut 0.3s;
}

/* Tiny screen */
.high-five-container {
  position: absolute;
  top: 53%;
  -webkit-tap-highlight-color: transparent;
}

/* Tiny screen rules only */
@media screen and (min-height: 380px) {
  .high-five-container {
    top: 65%;
  }
}

/* Mediumn screen rules only */
@media screen and (min-height: 650px) {
  .high-five-container {
    top: 67%;
  }

  .high-five-big {
    left: 80vw;
  }
}

/* Tall screen rules only */
@media screen and (min-height: 1000px) {
  .high-five-container {
    top: 69%;
  }
}


/* Widescreen rules only */
@media screen and (min-width: 900px) {
  .high-five-small {
    /* top: 60%; */
    left: 87vw;
  }

  .high-five-big {
    /* top: 60%; */
    left: 82vw;
  }
}

@-webkit-keyframes fadeInSlideUp {
  0% {
    opacity: 0%;
    -webkit-transform: translateY(80px);
  }
  100% {
    opacity: 75%;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInSlideUp {
  0% {
    opacity: 0%;
    transform: translateY(80px);
  }
  100% {
    opacity: 75%;
    transform: translateY(0);
  }
}
@keyframes fadeInSlideUp {
  0% {
    opacity: 0%;
    transform: translateY(80px);
  }
  100% {
    opacity: 75%;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 75%;
  }
  100% {
    opacity: 0%;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 75%;
  }
  100% {
    opacity: 0%;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 75%;
  }
  100% {
    opacity: 0%;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(60px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideOut {
 0% {
    -webkit-transform: translateX(-54px);
  }
  85% {
    -webkit-transform: translateX(88px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes slideOut {
  0% {
    transform: translateX(-54px);
  }
  85% {
    transform: translateX(88px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideOut {
 0% {
    transform: translateX(-54px);
  }
  85% {
    transform: translateX(88px);
  }
  100% {
    transform: translateX(0);
  }
}
html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  max-width: 100%;
  position: sticky;
  overflow-x: hidden;
  /* overflow: hidden; */
  height: calc(var(--vh, 1vh) * 100);
  touch-action: none
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Jumpman';
  src: local('Jumpman'), url(./fonts/Jumpman.ttf) format('truetype');
}

img {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



@media screen and (orientation:portrait) {
  body, html {
    position: fixed;
    overflow: hidden;
    height: 100vh;
  }
}


@media screen and (orientation:landscape) {
  body, html {
    position: sticky;
    overflow-x: hidden;
    height: calc(var(--vh, 1vh) * 100);
  }
}
/* 
@media screen and (max-width: 782px) {
	html.js {
		margin-top: 0 !important;
	}
	#wpadminbar {
		display: none;
	}
} */


/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 12px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #82B4EA; 
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a3d0ff;
}

::-webkit-scrollbar-corner       { display: none; }
